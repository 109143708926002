import React from 'react'
import { ValueFormat } from '../types'

const numberFormat = Intl.NumberFormat('en-US', {})
const percentFormat = Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

function formatStepValue(step: number) {
  const sign = step >= 0 ? '+' : ''

  return `${sign}${numberFormat.format(step)}`
}

function SummaryStep({ stepValue }: { stepValue?: number }) {
  if (stepValue === null || stepValue === undefined) {
    return null
  }

  return (
    <span className={'summary-value-step'}>{formatStepValue(stepValue)}</span>
  )
}

export const SummaryValue = React.memo(function SummaryValue({
  value,
  step,
  isFetching,
  valueFormat = ValueFormat.normal,
}: {
  value?: number | null
  step?: number
  isFetching: boolean
  valueFormat?: ValueFormat
}) {
  const valueOrZero = value ?? 0

  const displayString =
    valueFormat == ValueFormat.normal
      ? numberFormat.format(valueOrZero)
      : percentFormat.format(valueOrZero)

  return (
    <div className={`summary-value fetchable ${isFetching ? 'fetchable-fetching' : ''}`}>
      <span>{displayString}</span>
      <SummaryStep stepValue={step} />
    </div>
  )
})
