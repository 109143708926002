import { useEffect } from 'react'
import { StateCode } from '../config'

const STATE_CODE_KEY = 'statecode'

function getCurrentStateRoute(): StateCode | null {
  const hashCode = window.location.hash

  if (!hashCode) {
    return null
  }

  const decodedUriStr = decodeURIComponent(hashCode.slice(1))
  const parms = new URLSearchParams(decodedUriStr)

  return (parms.get(STATE_CODE_KEY) as StateCode) || null
}

/**
 * Responsible for storing and fetching state codes in URL
 */
export const stateRoute = {
  setStateRoute(stateCode: StateCode) {
    // const newHash = `${STATE_CODE_KEY}=${stateCode}`

    // window.location.hash = newHash

    window.history.replaceState(null, '', `#${STATE_CODE_KEY}=${stateCode}`)
  },

  get currentStateRoute(): StateCode | null {
    return getCurrentStateRoute()
  },

  useStateRoute(handler: (stateCode: StateCode | null) => void) {
    useEffect(() => {
      const listener = () => {
        const stateRoute = getCurrentStateRoute()
        console.log('hashchange')
        handler(stateRoute)
      }
      window.addEventListener('hashchange', listener)

      return () => {
        window.removeEventListener('hashchange', listener)
      }
    }, [handler])
  },
}
