import React, { useEffect, useRef, useState } from 'react'
import { StateCode, stateMap } from '../config'
import { useContainerSize } from '../hooks/useContainerSize'
import { geoJsonFor } from './stateSvgUtil'
import { drawStateOutline } from './stateDrawer'

export const StateOutline = React.memo(function StateOutline({
  stateCode,
  outlineClicked,
  isSelected,
}: {
  stateCode: StateCode
  outlineClicked: (stateCode: string) => void
  isSelected: boolean
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  const size = useContainerSize(containerRef)
  const [drawableState, setDrawableState] = useState<Boolean | null>(null)
  const stateName = stateMap[stateCode].name

  useEffect(() => {
    if (!size || !containerRef.current) {
      return
    }

    const geoJson = geoJsonFor(stateCode)

    if (!geoJson) {
      setDrawableState(false)
      return
    }

    drawStateOutline(containerRef.current, size, stateName, geoJson)
  }, [stateCode, size])

  return (
    <div
      className={`state-outline${isSelected ? ' selected' : ''}`}
      ref={containerRef}
      title={stateName}
      onClick={() => outlineClicked(stateCode)}
      onKeyDown={(event) => {
        if (
          event.key === ' ' ||
          event.key === 'Enter' ||
          event.key === 'Spacebar'
        ) {
          event.preventDefault()
          outlineClicked(stateCode)
        }
      }}
      role={'button'}
      tabIndex={0}
    >
      {drawableState === false ? (
        <div>
          <span>{stateName}</span>
        </div>
      ) : null}
    </div>
  )
})
