import { StateCode } from "../config"

const STORAGE_KEY = 'selectedStates'
const maxStateHistory = 100 // just some big number, UI will trim down to what it wants

export type SelectedState = {
  stateCode: string
}

type SeletedStateStore = {
  selectedStates: Array<SelectedState>
}

export const onStateSelected = (
  stateCode: StateCode
): Promise<Array<SelectedState>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const selectedStateJson = localStorage.getItem(STORAGE_KEY)

      let objToStore: SeletedStateStore = { selectedStates: [] }

      if (selectedStateJson) {
        objToStore = JSON.parse(selectedStateJson)
      }

      objToStore.selectedStates.unshift({ stateCode })
      objToStore.selectedStates = objToStore.selectedStates.slice(
        0,
        maxStateHistory
      )

      localStorage.setItem(STORAGE_KEY, JSON.stringify(objToStore))

      resolve(objToStore.selectedStates)
    }, 0)
  })
}

export const getSelectedStates = (): Promise<Array<SelectedState>> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const storedJson = JSON.parse(localStorage.getItem(STORAGE_KEY) || '{}')
      resolve(storedJson.selectedStates || [])
    }, 0)
  })
}

export const removeConfiguration = () => {
  localStorage.removeItem(STORAGE_KEY)
}
