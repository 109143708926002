const NUM_DAYS_AVG = 7

export function sevenDayAverage(
  vals: Array<number | null>
): Array<null | number> {
  const movingWindow: Array<number> = []
  const result = []

  for (let i = 0; i < vals.length; i++) {
    const v = vals[i]

    if (v === null) {
      result[i] = null
      continue
    }

    movingWindow.push(v)

    if (movingWindow.length > NUM_DAYS_AVG) {
      movingWindow.shift()
    }

    result[i] = avg(movingWindow)
  }

  return result
}

export function sevenDayAverageInPlace(
  vals: Array<{
    dataDate: Date
    number: number | null
    sevenDayAverage?: number | null
  }>
) {
  const movingWindow: Array<number> = []

  for (let i = 0; i < vals.length; i++) {
    const v = vals[i]

    if (v.number === null) {
      v.sevenDayAverage = null
      continue
    }

    movingWindow.push(v.number)

    if (movingWindow.length > NUM_DAYS_AVG) {
      movingWindow.shift()
    }

    v.sevenDayAverage = avg(movingWindow)
  }
}

function avg(vals: Array<number>) {
  if (vals.length < NUM_DAYS_AVG) {
    return null
  }

  let sum = 0

  for (const v of vals) {
    sum += v
  }

  return sum / vals.length
}
