import * as d3 from 'd3'
import { Size, ToolTipValue } from './types'

export type ToolTip = ReturnType<typeof createToolTip>

function clamp(low: number, high: number, value: number) {
  return Math.min(Math.max(low, value), high)
}

export function createToolTip({
  containerSize,
  dateOptions,
  valueOptions,
}: {
  containerSize: Size
  dateOptions: Intl.DateTimeFormatOptions
  valueOptions: Intl.NumberFormatOptions
}) {
  const xOffset = 28
  const toolTipHeight = 110
  const toolTipWidth = 110
  const toolTipStroke = 1 // TODO: this is defined in CSS, maybe have it here instead?
  const rectRelativeOrigin = -toolTipHeight + -20

  const dateTimeFormat = Intl.DateTimeFormat('en-US', dateOptions)
  const numberFormat = Intl.NumberFormat('en-US', valueOptions)
  const toolTip = d3
    .create('svg:g')
    .attr('display', 'none')
    .attr('class', 'tooltip')

  const rect = toolTip
    .append('rect')
    .attr('x', -94 + 20)
    .attr('y', rectRelativeOrigin)
    .attr('width', toolTipWidth)
    .attr('height', toolTipHeight)
    .attr('rx', '4')

  const date = toolTip
    .append('text')
    .attr('x', xOffset)
    .attr('y', rectRelativeOrigin + 20)
    .attr('class', 'tooltip-date')

  // TODO: Gross gross grossssss
  const name1 = toolTip
    .append('text')
    .attr('x', xOffset)
    .attr('y', rectRelativeOrigin + 40)
    .attr('class', 'tooltip-name')
  const value1 = toolTip
    .append('text')
    .attr('x', xOffset)
    .attr('y', rectRelativeOrigin + 55)
    .attr('class', 'tooltip-value')

  const name2 = toolTip
    .append('text')
    .attr('x', xOffset)
    .attr('y', rectRelativeOrigin + 75)
    .attr('class', 'tooltip-name')
  const value2 = toolTip
    .append('text')
    .attr('x', xOffset)
    .attr('y', rectRelativeOrigin + 90)
    .attr('class', 'tooltip-value')

  return {
    node: toolTip,
    setValue: (dateValue: Date, values: Array<ToolTipValue>) => {
      const formattedValue1 =
        values[0].value !== null ? numberFormat.format(values[0].value) : 'N/A'
      name1.text(values[0].name)
      value1.text(formattedValue1)

      const formattedValue2 =
        values[1].value !== null ? numberFormat.format(values[1].value) : 'N/A'
      name2.text(values[1].name)
      value2.text(formattedValue2)

      date.text(dateTimeFormat.format(dateValue))
    },
    setLocation: (x: number, y: number) =>
      toolTip.attr(
        'transform',
        `translate(${x < toolTipWidth ? x + toolTipWidth + 4 : x}, ${clamp(
          containerSize.height * 0.05 + -rectRelativeOrigin,
          containerSize.height * 0.8,
          y
        )})`
      ),
    hide: () => toolTip.attr('display', 'none'),
    show: () => toolTip.attr('display', null),
  }
}
