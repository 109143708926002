export function getDateFromNumber(dateNum: number): Date {
  const now = new Date()

  const year = Math.floor(dateNum / 10000)
  const month = Math.floor((dateNum % 10000) / 100)
  const day = Math.floor(dateNum % 100)

  now.setFullYear(year)
  now.setMonth(month - 1)
  now.setDate(day)

  return now
}
