import { StateCode } from './config'
import { CaseData, DailyData, DeceasedData, TestingData } from './types'
import { DateRange } from './util/DateRange'
import { getDateFromNumber } from './util/dateUtil'

export function fetchDailyData(state: string) {
  const path =
    state === 'us' ? 'v1/us/daily.json' : `v1/states/${state}/daily.json`

  return fetch(`https://api.covidtracking.com/${path}`).then((response) =>
    response.json()
  )
}

type CDCMinimalDailyType = {
  submission_date: string
  state: string
  tot_cases: string
  conf_cases: string
  new_case: string
  tot_death: string
  conf_death: string
  new_death: string
}

type CDCFullDailyType = CDCMinimalDailyType & {
  conf_cases: string
  prob_cases: string
  pnew_case: string
  conf_death: string
  prob_death: string
  pnew_death: string
  created_at: string
  consent_cases: string
  consent_deaths: string
}

const ye = new Intl.DateTimeFormat('en', { year: 'numeric' })
const mo = new Intl.DateTimeFormat('en', { month: '2-digit' })
const da = new Intl.DateTimeFormat('en', { day: '2-digit' })

type DaySum = {
  deathIncrease: number
  totalDeaths: number
  positiveIncrease: number
}

function formatDate(date: Date) {
  return parseInt(`${ye.format(date)}${mo.format(date)}${da.format(date)}`)
}

export async function fetchUSCDCDailyData(
  dateRange: DateRange
): Promise<DailyData> {
  let responseData: Array<CDCMinimalDailyType> = []

  const maxNumberOfRecords = numberOfRecordsToFetch([StateCode.us], dateRange)

  try {
    const response = await fetch(
      `https://data.cdc.gov/resource/9mfq-cb36.json?$select=state,new_case,new_death,tot_death,submission_date&$limit=${maxNumberOfRecords}&$order=submission_date+desc`
    )
    responseData = await response.json()
  } catch (error) {
    console.error(error)
  }

  if (!responseData.length) {
    console.warn(`No data returned for US`)
    return []
  }

  const dayMap = new Map<number, DaySum>()
  const dateCutoff = new Date()
  dateCutoff.setDate(dateCutoff.getDate() - dateRange.numberOfDays)
  const dateCutoffNumber = formatDate(dateCutoff)

  responseData.forEach((x) => {
    const dateNumber = formatDate(new Date(x.submission_date))

    // skip any old dates
    if (dateNumber < dateCutoffNumber) {
      return
    }

    let sum = dayMap.get(dateNumber)

    if (!sum) {
      sum = { deathIncrease: 0, positiveIncrease: 0, totalDeaths: 0 }
      dayMap.set(dateNumber, sum)
    }

    sum.deathIncrease += parseInt(x.new_death) || 0
    sum.totalDeaths += parseInt(x.tot_death) || 0
    sum.positiveIncrease += parseInt(x.new_case) || 0
  })

  const dailyData = Array.from(dayMap, ([date, daySum]) => ({
    date,
    dataDate: getDateFromNumber(date),
    death: daySum.totalDeaths,
    positiveIncrease: daySum.positiveIncrease,
    deathIncrease: daySum.deathIncrease,
  }))

  dailyData.sort((a, b) => {
    if (a.date < b.date) return 1
    if (a.date > b.date) return -1
    return 0
  })

  return dailyData
}

function numberOfRecordsToFetch(
  stateFilter: Array<string>,
  dateRange: DateRange
): number {
  const usIndex = stateFilter.indexOf(StateCode.us)

  if (usIndex > -1) {
    return dateRange.numberOfDays * 54
  }

  return stateFilter.length * dateRange.numberOfDays
}

function stateCodes(stateCode: StateCode): Array<string> {
  if (stateCode === StateCode.ny) {
    return [StateCode.ny, 'NYC']
  }

  return [stateCode]
}

export async function fetchCDCData(
  state: StateCode,
  dateRange: DateRange
): Promise<DailyData> {
  let responseData: Array<CDCMinimalDailyType> = []
  const stateCodeStrings = stateCodes(state)
  const maxNumberOfRecords = numberOfRecordsToFetch(stateCodeStrings, dateRange)
  const stateFilter =
    state === StateCode.us
      ? ''
      : encodeURIComponent(
          `state in ( ${stateCodeStrings
            .map((x) => `'${x.toUpperCase()}'`)
            .join(',')} )`
        )

  try {
    const response = await fetch(
      `https://data.cdc.gov/resource/9mfq-cb36.json?${
        stateFilter && `$where=${stateFilter}&`
      }$select=state,new_case,new_death,tot_death,submission_date&$limit=${maxNumberOfRecords}&$order=submission_date+desc`
    )
    responseData = await response.json()
  } catch (error) {
    console.error(error)
  }

  if (!responseData.length) {
    console.warn(`No data returned for ${state}`)
    return []
  }

  const dayMap = new Map<number, DaySum>()
  const dateCutoff = new Date()
  dateCutoff.setDate(dateCutoff.getDate() - dateRange.numberOfDays)
  const dateCutoffNumber = formatDate(dateCutoff)

  responseData.forEach((x) => {
    const dateNumber = formatDate(new Date(x.submission_date))

    // skip any old dates
    if (dateNumber < dateCutoffNumber) {
      return
    }

    let sum = dayMap.get(dateNumber)

    if (!sum) {
      sum = { deathIncrease: 0, positiveIncrease: 0, totalDeaths: 0 }
      dayMap.set(dateNumber, sum)
    }

    sum.deathIncrease += parseInt(x.new_death) || 0
    sum.totalDeaths += parseInt(x.tot_death) || 0
    sum.positiveIncrease += parseInt(x.new_case) || 0
  })

  const dailyData = Array.from(dayMap, ([date, daySum]) => ({
    date,
    dataDate: getDateFromNumber(date),
    death: daySum.totalDeaths,
    positiveIncrease: daySum.positiveIncrease,
    deathIncrease: daySum.deathIncrease,
  }))

  dailyData.sort((a, b) => {
    if (a.date < b.date) return 1
    if (a.date > b.date) return -1
    return 0
  })

  return dailyData
}

export function fetchCDCDailyData(
  state: StateCode,
  dateRange: DateRange
): Promise<DailyData> {
  return fetch(
    `https://data.cdc.gov/resource/9mfq-cb36.json?state=${state.toUpperCase()}&$limit=${
      dateRange.numberOfDays
    }&$order=submission_date+desc`
  )
    .then((response) => response.json())
    .then((value) => {
      if (!value.length) {
        console.warn(`No data returned for ${state}`)
        return []
      }

      return value.map((x: CDCFullDailyType) => {
        const dataDate = new Date(x.submission_date)
        return {
          state,
          death: parseInt(x.tot_death),
          deathIncrease: parseInt(x.new_death),
          positiveIncrease: parseInt(x.new_case),
          date: formatDate(dataDate),
          dataDate,
        }
      })
    })
}

type SingleDayData = {
  new: number
  tot: number
  d: string
}

type TestDayData = {
  d: string
  num: number
}

export function fetchTestingData(
  state: StateCode,
  dateRange: DateRange
): Promise<TestingData> {
  return fetch(`/static/test_data/${state.toLowerCase()}.json`)
    .then((response) => response.json())
    .then((testData: Array<TestDayData>) => {
      if (!testData.length) {
        console.warn(`No data returned for ${state}`)
        return []
      }

      return testData
        .map((x) => ({
          date: x.d,
          dataDate: new Date(x.d),
          testCount: x.num,
        }))
        .filter(
          (x) => x.dataDate >= dateRange.start && x.dataDate <= dateRange.end
        )
    })
}

export function fetchCaseData(
  state: StateCode,
  dateRange: DateRange
): Promise<CaseData> {
  return fetch(`/static/case_data/${state.toLowerCase()}.json`)
    .then((response) => response.json())
    .then((caseData: Array<SingleDayData>) => {
      if (!caseData.length) {
        console.warn(`No case data returned for ${state}`)
        return []
      }

      return caseData
        .map((x) => ({
          date: x.d,
          dataDate: new Date(x.d),
          caseIncrease: x.new,
        }))
        .filter(
          (x) => x.dataDate >= dateRange.start && x.dataDate <= dateRange.end
        )
    })
}

export function fetchDeceasedData(
  state: StateCode,
  dateRange: DateRange
): Promise<DeceasedData> {
  return fetch(`/static/deceased_data/${state.toLowerCase()}.json`)
    .then((response) => response.json())
    .then((caseData: Array<SingleDayData>) => {
      if (!caseData.length) {
        console.warn(`No case data returned for ${state}`)
        return []
      }

      return caseData
        .map((x) => ({
          date: x.d,
          dataDate: new Date(x.d),
          deceasedIncrease: x.new,
          totalDeceased: x.tot,
        }))
        .filter(
          (x) => x.dataDate >= dateRange.start && x.dataDate <= dateRange.end
        )
    })
}
