import { useEffect, useState } from 'react'
import { Size } from '../chart/types'

export function useViewportSize() {
  const [viewportSize, setViewportSize] = useState<Size>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    function updateContainerWidth() {
      setViewportSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', updateContainerWidth)

    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [])

  return viewportSize
}
