import { stateMap } from '../config'
import { stateGeoData } from './statesGeo'

const stateGeoLookup = stateGeoData.features.reduce(
  (lookup: Map<string, any>, feature: any) => {
    lookup.set(feature.properties.name as string, feature)
    return lookup
  },
  new Map<string, any>()
)

export function geoJsonFor(stateCode: string): any | null {
  const stateConfig = stateMap[stateCode]

  if (!stateConfig) {
    return null
  }

  if (stateCode === 'us') {
    return stateGeoData
  }

  return stateGeoLookup.get(stateConfig.name) || null
}
