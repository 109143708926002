import { DayData } from '../types'

export function percentPositive(day: DayData): number {
  if (!day.totalTestResultsIncrease) return 0

  const ratio = (day.positiveIncrease || 0) / day.totalTestResultsIncrease
  // TODO: find some way to indicate or omit this, as it's an outlier
  if (ratio >= 1) return 0

  return Math.min(ratio, 1)
}
