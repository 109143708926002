import * as d3 from 'd3'
import { Size } from '../chart/types'

export function drawStateOutline(
  container: HTMLElement,
  size: Size,
  stateName: string,
  geoJson: any
) {
  if (!geoJson) {
    return
  }
  const projection = d3
    .geoAlbersUsa()
    .fitSize([size.width, size.height], geoJson)

  const path = d3.geoPath(projection)

  d3.select(container).selectAll('*').remove().append('svg')

  const svg = d3
    .select(container)
    .append('svg')
    .attr('width', size.width)
    .attr('height', size.height)

  svg.selectAll('path').data([geoJson]).enter().append('path').attr('d', path)
}
