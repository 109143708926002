import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { GraphContext } from '../hooks/GraphContext'
import { ValueFormat } from '../types'
import { StateName } from './StateName'
import { SummaryValue } from './SummaryValue'

const dateFormat = Intl.DateTimeFormat('en-US', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
})

export const StateSummary = observer(function StateSummary() {
  const graphs = useContext(GraphContext)

  const data = graphs.stateSummary

  const formattedDate =
    data && data.dataCurrency
      ? dateFormat.format(data.dataCurrency)
      : 'ABCDE 00, 2000'
  const dataCurrentAsOf = `Data current as of ${formattedDate}`

  return (
    <div className={'state-summary'}>
      <StateName selectedState={graphs.currentStateCode} />
      <div className={'summary-container'}>
        <div>
          <h3>Deaths</h3>
          <SummaryValue
            isFetching={graphs.isFetchingData}
            value={data?.totalDeaths}
            step={data?.newDeaths}
          />
        </div>
        <div>
          <h3>New Cases</h3>
          <SummaryValue
            isFetching={graphs.isFetchingData}
            value={data?.newCases}
          />
        </div>
        <div>
          <h3>Cases per 1M</h3>
          <SummaryValue
            isFetching={graphs.isFetchingData}
            value={data?.casesPer1M}
          />
        </div>
        {/* <div>
          <h3>Currently Hospitalized</h3>
          <SummaryValue value={values.hospitalizedCurrently} />
        </div> */}
        <div>
          <h3>Percent Positive</h3>
          <SummaryValue
            valueFormat={ValueFormat.percent}
            isFetching={graphs.isFetchingData}
            value={data?.percentPositive}
          />
        </div>
      </div>
      <div className={'summary-disclaimer'}>
        {/* {data && data.dataQualityGrade && (
          <div>
            Data grade:
            <StateDataGrade dataQualityGrade={data.dataQualityGrade} />
          </div>
        )} */}
        <span
          className={`fetchable ${
            graphs.isFetching ? 'fetchable-fetching' : ''
          }`}
        >
          {dataCurrentAsOf}
        </span>
      </div>
    </div>
  )
})
