import React, { useEffect, useState } from 'react'
import { StateCode } from '../config'
import { useLayoutSize } from '../hooks/useLayoutSize'
import { getSelectedStates } from './selectedStatesStore'
import { StateOutline } from './StateOutline'

// https://mapshaper.org/

export const StateOutlineContainer = React.memo(
  ({
    selectedState,
    outlineClicked,
  }: {
    selectedState: StateCode
    outlineClicked: (stateCode: StateCode) => void
  }) => {
    const [statesToDraw, setStatesToDraw] = useState<Array<StateCode>>([])
    const layoutSize = useLayoutSize()

    useEffect(() => {
      getSelectedStates().then((storedStates) => {
        const dedupedStateCodes = [
          ...new Set(storedStates.map((x) => x.stateCode as StateCode)),
        ]

        setStatesToDraw(dedupedStateCodes.slice(1, 6))
      })
    }, [selectedState])

    if (layoutSize.isSmallest) {
      return null
    }

    const numberOfStatesToDraw = layoutSize.isLargest ? 5 : 3

    return (
      <div className={'state-outline-container'}>
        {statesToDraw.slice(0, numberOfStatesToDraw).map((code) => (
          <StateOutline
            outlineClicked={outlineClicked}
            key={`${code}`}
            stateCode={code}
            isSelected={selectedState === code}
          />
        ))}
      </div>
    )
  }
)
