import React, { useEffect, useRef, useState } from 'react'
import { StateCode, stateMap } from '../config'
import { useContainerSize } from '../hooks/useContainerSize'
import { drawStateOutline } from './stateDrawer'
import { geoJsonFor } from './stateSvgUtil'
import { createUSSvg } from './usSvg'

export const StateButton = React.memo(function StateButton({
  code,
  onSelected,
  isSelected,
  showLabel = false,
}: {
  code: StateCode
  onSelected: (code: string) => void
  name: string
  isSelected: boolean
  showLabel?: boolean
}) {
  const containerRef = useRef<HTMLDivElement>(null)
  const size = useContainerSize(containerRef)
  const [drawableState, setDrawableState] = useState<Boolean | null>(null)
  const stateName = stateMap[code].name

  useEffect(() => {
    if (!size || !containerRef.current) {
      return
    }

    if (code === 'us') {
      containerRef.current.innerHTML = createUSSvg(size.width, size.height)
      return
    }

    const geoJson = geoJsonFor(code)

    if (!geoJson) {
      setDrawableState(false)
      return
    }

    drawStateOutline(containerRef.current, size, stateName, geoJson)
  }, [code, size])

  return (
    <div
      className={`state-button${isSelected ? ' selected' : ''}`}
      title={stateName}
      onClick={() => onSelected(code)}
      onKeyDown={(event) => {
        if (
          event.key === ' ' ||
          event.key === 'Enter' ||
          event.key === 'Spacebar'
        ) {
          event.preventDefault()
          onSelected(code)
        }
      }}
      role={'button'}
      tabIndex={0}
    >
      <div className="outline-container" ref={containerRef}></div>
      {showLabel && <div>{code.toUpperCase()}</div>}
      {drawableState === false ? (
        <div>
          <span>{stateName}</span>
        </div>
      ) : null}
    </div>
  )
})
