import { Instance } from 'mobx-state-tree'
import React from 'react'
import { StateCode } from '../config'
import { Graphs } from '../model/Graphs'
import { DateRange } from '../util/DateRange'
import { stateRoute } from '../util/stateRoute'

// TODO: this should drive the picker as well...?
const initialRange = DateRange.fromDays(90)

export const graphs = Graphs.create({
  currentStateCode: stateRoute.currentStateRoute || StateCode.mn,
  dateRange: {
    start: initialRange.start,
    numberOfDays: initialRange.numberOfDays,
    end: initialRange.end,
  },
})

export const GraphContext = React.createContext<Instance<typeof Graphs>>(graphs)
