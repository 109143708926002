import React from 'react'
import { StateCode, stateMap } from '../config'

export function StateName({ selectedState }: { selectedState: StateCode }) {
  return (
    <div className={'name-container'}>
      <h1>{stateMap[selectedState].name}</h1>
    </div>
  )
}
