import { observer } from 'mobx-react-lite'
import { Instance } from 'mobx-state-tree'
import React, { useEffect, useRef } from 'react'
import { useContainerSize } from '../hooks/useContainerSize'
import { GraphChart } from '../model/Graphs'
import { ChartType } from '../types'
import { drawDailyChart } from './chartDrawing'
import { DataDimension } from './types'

type ChartProps = {
  chart: Instance<typeof GraphChart>
  isFetching: boolean
  name: string
}

export const Chart = observer(({ chart, isFetching, name }: ChartProps) => {
  const svgRef = useRef<SVGSVGElement>(null)
  const chartBoundsRef = useRef<HTMLDivElement>(null)
  const size = useContainerSize(chartBoundsRef)

  const startDate = chart.startDate
  const endDate = chart.endDate
  const data: Array<DataDimension> = [
    { name: chart.name, data: chart.data.slice(), display: ChartType.bar },
    {
      name: 'Seven Day Avg',
      data: chart.sevenDayAverage.slice(),
      display: ChartType.line,
    },
  ]
  const chartName = chart.name
  const valueFormat = chart.format

  useEffect(() => {
    if (!size || !svgRef.current) return
    const state = drawDailyChart(
      {
        startDate,
        endDate,
        data,
        name: chartName,
        valueFormat,
      },
      size.width,
      size.height,
      svgRef.current
    )
    return () => state.cleanUp()
  }, [chart, svgRef, size, startDate, endDate, data, chartName, valueFormat])

  return (
    <div className={'chart'}>
      <div>
        <h2>{name}</h2>
      </div>
      <div
        className={`chart-bounds fetchable ${
          isFetching ? 'fetchable-fetching' : ''
        }`}
        ref={chartBoundsRef}
      >
        <svg ref={svgRef} className="chart-viewport"></svg>
      </div>
    </div>
  )
})
