import * as d3 from 'd3'
import { ValueFormat } from '../types'
import { withVerticalMouseStep } from './chartEvents'
import { createToolTip, ToolTip } from './toolTip'
import { DailyChart, Rect, ToolTipValue } from './types'

type ChartState = {
  cleanUp: () => void
}

type DailyChartRenderOptions = {
  width: number
  height: number
  container: SVGSVGElement
  chartConfig: DailyChart
}

type RenderResult = {
  lineIndicator: d3.Selection<SVGPathElement, unknown, null, undefined>
  toolTip: ToolTip
  locationInterpolation: (value: number) => number
  chartBoundingBox: Rect
  dataStep: number
}

function renderChart({
  chartConfig,
  width,
  height,
  container,
}: DailyChartRenderOptions): RenderResult {
  const yAxisGutterWidth = 38
  const xAxisGutterHeight = 20
  const paddingRight = 35
  // TODO: gross/hack
  const barData = chartConfig.data[0].data
  const lineData = chartConfig.data[1].data
  const barWidth = (width - yAxisGutterWidth - paddingRight) / barData.length

  d3.select(container).selectAll('*').remove()

  const max =
    d3.max(barData.filter((x: null | number) => x !== null) as Array<number>) ||
    0
  const maxDomain =
    chartConfig.valueFormat === ValueFormat.percent
      ? Math.max(max, 0.5) + 0.05
      : Math.ceil(max * 1.15)

  const y = d3
    .scaleLinear()
    .domain([0, maxDomain])
    .range([height - xAxisGutterHeight, 0])

  const chart = d3
    .select(container)
    .attr('width', `${width}`)
    .attr('height', `${height}`)
    .attr('viewBox', `0 0 ${width} ${height}`) // I don't understand this attribute

  chart
    .append('g')
    .attr('class', 'grid')
    .attr('transform', `translate(${yAxisGutterWidth}, 0)`)
    .call(
      d3
        .axisLeft(y)
        .tickSize(-width - yAxisGutterWidth)
        // @ts-ignore
        .tickFormat('')
    )

  // bar graph drawing
  const barSelection = chart
    .selectAll('.barGroup')
    .data(barData.map((x) => x ?? 0))
    .enter()

  // <path fill="red" d="M50,0 v260 q0,2 2,2 h4 q2,0 2,-2 v-260 h-4 z"></path>

  barSelection
    .append('g')
    .attr('class', 'barGroup')
    .attr(
      'transform',
      (d, i) => `translate(${barWidth * i + yAxisGutterWidth}, ${y(d)})`
    )
    // .append('rect')
    // .attr('width', barWidth)
    // .attr('height', (d, i) => Math.max(0, height - y(d) - xAxisGutterHeight))
    .append('path')
    .attr('class', 'bar-value')
    // Amazing blog post detailing how to get these rounded corners:
    // https://medium.com/@dennismphil/one-side-rounded-rectangle-using-svg-fb31cf318d90
    .attr('d', (d, i) => {
      const computedY = y(d)
      const startPoint = Math.max(0, height - computedY - xAxisGutterHeight)

      if (!d) {
        return `M0,${startPoint} v0 h${barWidth} v0 h${-barWidth} z`
      }

      const borderRadius = (barWidth * 0.6) / 2
      const barHeight = startPoint - borderRadius
      const firstBezier = `q0,${-borderRadius} ${borderRadius},${-borderRadius}`
      const secondBezier = `q${borderRadius},0 ${borderRadius},${borderRadius}`
      const horizontal = barWidth * 0.4

      return `M0,${startPoint} v${-barHeight} ${firstBezier} h${horizontal} ${secondBezier} v${barHeight} h${-horizontal} z`
    })

  // MARK: line chart portion
  chart
    .append('path')
    .datum(lineData)
    .attr('class', 'avg-line')
    .attr(
      'd',
      d3
        .line()
        .defined((d) => d !== null)
        .x(function (d, i) {
          return barWidth * i + yAxisGutterWidth + barWidth / 2
        })
        .y(function (d) {
          return y(d)
        })
    )

  // Setting up x/y axis
  const dateScale = d3
    .scaleTime()
    .domain([chartConfig.startDate, chartConfig.endDate])
    .range([0, width - yAxisGutterWidth - paddingRight])

  const isLargeDateRange =
    chartConfig.endDate.getTime() - chartConfig.startDate.getTime() >
    23328000000
  const monthTicks = isLargeDateRange ? 3 : 1
  const timeFormat = isLargeDateRange ? "%b '%y" : '%b %e'

  const xAxis = d3
    .axisBottom(dateScale)
    .tickFormat(d3.timeFormat(timeFormat))
    .ticks(d3.timeMonth.every(monthTicks))

  const yAxisFormat =
    chartConfig.valueFormat === ValueFormat.percent ? '.0%' : '.2s'
  const yAxis = d3.axisLeft(y).tickFormat(d3.format(yAxisFormat))

  chart
    .append('g')
    .attr('class', 'y-axis')
    .attr('transform', `translate(${yAxisGutterWidth}, 0)`)
    .call(yAxis)

  chart
    .append('g')
    .attr('class', 'x-axis')
    .attr(
      'transform',
      'translate(' + [yAxisGutterWidth, height - xAxisGutterHeight] + ')'
    )
    .call(xAxis)

  // Dashes portion (shown via mouse over/tap)
  const line = d3.line()([
    [yAxisGutterWidth, 0],
    [yAxisGutterWidth, height - xAxisGutterHeight],
  ])

  const dots = chart
    .append('path')
    .attr('class', 'dots')
    .attr('display', 'none')
    // @ts-ignore
    .attr('d', line)

  const toolTip = createToolTip({
    containerSize: { width, height },
    dateOptions: { day: 'numeric', month: 'short', year: 'numeric' },
    valueOptions:
      chartConfig.valueFormat === ValueFormat.percent
        ? {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        : { maximumFractionDigits: 2 },
  })
  chart.append(() => toolTip.node.node())

  return {
    lineIndicator: dots,
    toolTip,
    locationInterpolation: y,
    chartBoundingBox: {
      x: yAxisGutterWidth,
      y: 0,
      width: width - yAxisGutterWidth - paddingRight,
      height: height - xAxisGutterHeight,
    },
    dataStep: barWidth,
  }
}

export function drawDailyChart(
  chartConfig: DailyChart,
  width: number,
  height: number,
  container: SVGSVGElement
): ChartState {
  const {
    chartBoundingBox,
    dataStep,
    locationInterpolation,
    toolTip,
    lineIndicator,
  } = renderChart({
    chartConfig,
    width,
    height,
    container,
  })

  let toolTipIsDisplayed = false

  const dates = d3
    .scaleTime()
    .domain([0, chartConfig.data[0].data.length - 1])
    // @ts-ignore
    .range([chartConfig.startDate, chartConfig.endDate])

  const mouseHandler = withVerticalMouseStep(
    container,
    chartBoundingBox,
    dataStep,
    (step, location, mouseLocation) => {
      if (step === -1) {
        lineIndicator.attr('display', 'none')
        toolTip.hide()
        toolTipIsDisplayed = false
      } else {
        const values: Array<ToolTipValue> = chartConfig.data.map((x) => ({
          name: x.name,
          value: x.data[step],
        }))
        const date = dates(step)

        lineIndicator
          .attr('display', null)
          .attr('transform', `translate(${location}, 0)`)
        if (!toolTipIsDisplayed) {
          toolTip.show()
          toolTipIsDisplayed = true
        }

        // TODO: what's up with the type here
        toolTip.setValue(date, values)
        toolTip.setLocation(
          location,
          locationInterpolation(values[0].value ?? 0)
        )
      }
    }
  )

  return {
    cleanUp: () => {
      mouseHandler.cleanUp()
    },
  }
}
