import React, { ChangeEventHandler, useState } from 'react'
import { DateRange } from './util/DateRange'

type Options = 'last90' | 'last45' | 'last150' | 'alltime' | 'custom'

export function DatePicker({
  dateRangeSelected,
  initialSelection,
}: {
  initialSelection: Options
  dateRangeSelected: (range: DateRange) => void
}) {
  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const option = e.target.value as Options

    let dateRange: DateRange | null = null

    switch (option) {
      case 'last45':
        dateRange = DateRange.fromDays(45)
        break
      case 'last90':
        dateRange = DateRange.fromDays(90)
        break
      case 'last150':
        dateRange = DateRange.fromDays(150)
        break
      case 'alltime':
        dateRange = DateRange.fromDates(new Date('2020/4/1'), new Date())
        break
      default:
        throw new Error('Unknown date range option')
    }

    dateRangeSelected(dateRange)
  }

  return (
    <select defaultValue={initialSelection} onChange={handleChange}>
      <option value="last45">Last 45 days</option>
      <option value="last90">Last 90 days</option>
      <option value="last150">Last 150 days</option>
      <option value="alltime">All Time</option>
    </select>
  )
}
