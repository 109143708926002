import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { ChartContainer } from './chart/ChartContainer'
import { StateCode } from './config'
import { DatePicker } from './DatePicker'
import { GraphContext, graphs } from './hooks/GraphContext'
import { onStateSelected } from './stateOutline/selectedStatesStore'
import { StateOutlineContainer } from './stateOutline/StateOutlineContainer'
import { StatePickerV2 } from './StatePickerV2'
import { StateSummary } from './summary/StateSummary'
import { stateRoute } from './util/stateRoute'

// sample map: https://codesandbox.io/s/d3js-8z192?file=/src/index.js

const App = observer(() => {
  const statePicked = useCallback((state: StateCode) => {
    graphs.setState(state)

    // TODO: can move to the mobx state tree
    onStateSelected(state)
  }, [])

  useEffect(() => {
    if (graphs.currentStateCode) {
      stateRoute.setStateRoute(graphs.currentStateCode)
    }
  }, [graphs.currentStateCode])

  return (
    <GraphContext.Provider value={graphs}>
      <>
        <div className="actionbar">
          <StatePickerV2
            selectedState={graphs.currentStateCode}
            statePicked={statePicked}
          />
          <StateOutlineContainer
            outlineClicked={statePicked}
            selectedState={graphs.currentStateCode}
          />
          <DatePicker
            initialSelection="last90"
            dateRangeSelected={(dateRange) => {
              graphs.setDateRange(dateRange)
            }}
          />
        </div>

        {/* TODO: add error handing (hasErrored === true) */}
        <StateSummary />

        <ChartContainer />

        {/* DEBUG SECTION */}
        {/* <div>Current state: {graphs.currentStateCode}</div>
        <div>Chart size: {graphs.charts?.length}</div>

        <div>
          {graphs.charts?.map((x) => {
            console.log(JSON.stringify(x.data))
            return (
              <div>
                <div>name: {x.name}</div>
                <div>is fetching: {x.isFetching.toString()}</div>
                <div>start date: {x.startDate?.toString()}</div>
                <div>end date: {x.endDate?.toString()}</div>
                <div>data length: {x.data.length}</div>
                <div>data first: {JSON.stringify(x.data[0])}</div>
                <div>
                  data first: {JSON.stringify(x.data[x.data.length - 1])}
                </div>
              </div>
            )
          })}
        </div> */}
      </>
    </GraphContext.Provider>
  )
})

;(() => {
  ReactDOM.render(<App />, document.getElementById('container'))
})()
