import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { GraphContext } from '../hooks/GraphContext'
import { Chart } from './Chart'

export const ChartContainer = observer(() => {
  const graphs = useContext(GraphContext)

  const charts = graphs.charts.map((d, index) => (
    <Chart
      key={index.toString()}
      isFetching={graphs.isFetchingData}
      chart={d}
      name={d.name}
    />
  ))

  return <div className={'chart-container'}>{charts}</div>
})
