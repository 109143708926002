import { ChartType, DailyData, DayData, ValueFormat } from '../types'
import { getDateFromNumber } from '../util/dateUtil'
import { percentPositive } from '../util/stats'
import { DailyChart } from './types'

type SingleChartConfig = {
  name: string
  projection: (data: DailyData) => Array<number>
  selectValue: (data: DayData) => number | null
  valueFormat: ValueFormat
}

type ChartConfig = {
  charts: Array<SingleChartConfig>
}

export function mapDailyData(data: DailyData): Array<DailyChart> {
  if (data.length === 0) {
    return []
  }

  data = data.slice()
  // scrub out data from ~before Mar 1, 2020, as it's mostly garbage.
  const march1Index = data.findIndex((day) => day.date === 20200223)

  if (march1Index !== -1) {
    data = data.slice(0, march1Index + 1)
  }

  const startDate = getDateFromNumber(data[data.length - 1].date || 20200301)
  const endDate = getDateFromNumber(data[0].date || 20200301)

  data.reverse()

  return chartConfig.charts.map((chart) => {
    return {
      name: chart.name,
      data: [
        {
          data: chart.projection(data),
          name: chart.name,
          display: ChartType.bar,
        },
      ],
      startDate,
      endDate,
      valueFormat: chart.valueFormat,
    }
  })
}

export const chartConfig: ChartConfig = {
  charts: [
    {
      name: 'New Positive Cases',
      projection: (data) => data.map((day) => day.positiveIncrease || 0),
      selectValue: (data) => data.positiveIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'New Deaths',
      projection: (data) => data.map((day) => day.deathIncrease || 0),
      selectValue: (data) => data.deathIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'New Tests',
      projection: (data) =>
        data.map((day) => day.totalTestResultsIncrease || 0),
      selectValue: (data) => data.totalTestResultsIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'Total Hospitalizations',
      projection: (data) => data.map((day) => day.hospitalizedCurrently || 0),
      selectValue: (data) => data.hospitalizedCurrently ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'Percent Positive',
      valueFormat: ValueFormat.percent,
      projection: (data) => {
        return data.map(percentPositive)
      },
      selectValue: (data) => 0,
    },
  ],
}

export const supportedCharts: ChartConfig = {
  charts: [
    {
      name: 'New Cases',
      projection: (data) => data.map((day) => day.positiveIncrease || 0),
      selectValue: (data) => data.positiveIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'New Deaths',
      projection: (data) => data.map((day) => day.deathIncrease || 0),
      selectValue: (data) => data.deathIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    {
      name: 'New Tests',
      projection: (data) =>
        data.map((day) => day.totalTestResultsIncrease || 0),
      selectValue: (data) => data.totalTestResultsIncrease ?? 0,
      valueFormat: ValueFormat.normal,
    },
    // {
    //   name: 'Total Hospitalizations',
    //   projection: (data) => data.map((day) => day.hospitalizedCurrently || 0),
    //   valueFormat: ValueFormat.normal,
    // },
    // {
    //   name: 'Percent Positive',
    //   valueFormat: ValueFormat.percent,
    //   projection: (data) => {
    //     return data.map(percentPositive)
    //   },
    // },
  ],
}
