import { useState, useEffect, RefObject } from 'react'
import { Size } from '../chart/types'

function getSize(ele: HTMLElement): Size {
  const style = getComputedStyle(ele)
  const left = parseFloat(style.paddingLeft)
  const right = parseFloat(style.paddingRight)
  const top = parseFloat(style.paddingTop)
  const bottom = parseFloat(style.paddingBottom)

  return {
    width: ele.clientWidth - left - right,
    height: ele.clientHeight - top - bottom,
  }
}

export function useContainerSize(containerRef: RefObject<HTMLElement>) {
  const [size, setSize] = useState<Size | null>(null)

  useEffect(() => {
    if (!containerRef.current) {
      return
    }
    setSize(getSize(containerRef.current))
  }, [containerRef])

  useEffect(() => {
    function updateContainerWidth() {
      if (!containerRef.current) {
        return
      }
      setSize(getSize(containerRef.current))
    }

    window.addEventListener('resize', updateContainerWidth)

    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [containerRef])

  return size
}

