import { flow, types } from 'mobx-state-tree'
import { supportedCharts } from '../chart/chartConfig'
import { StateCode, stateMap } from '../config'
import { fetchCDCData, fetchTestingData } from '../dailyDataService'
import { DailyData, TestingData, ValueFormat } from '../types'
import { findLastIndex } from '../util/arrayUtils'
import { DateRange } from '../util/DateRange'
import { getDateFromNumber } from '../util/dateUtil'
import { normalizeData } from './dataNormalizer'

export const GraphChart = types.model('Chart', {
  errored: types.boolean,
  isFetching: types.boolean,
  name: types.string,
  format: types.enumeration<ValueFormat>(
    'ValueFormat',
    Object.values(ValueFormat)
  ),
  // TODO: how to track data...? We want a big blob of stuff that isn't observable
  //   blah: types.maybe(types.frozen(types.array(types.number))),
  data: types.array(types.maybeNull(types.number)),
  sevenDayAverage: types.array(types.maybeNull(types.number)),
  startDate: types.Date,
  endDate: types.Date,
})

const DateRangeTreeType = types.model('DateRange', {
  numberOfDays: types.number,
  start: types.Date,
  end: types.Date,
})

const StateSummary = types.model('StateSummary', {
  totalDeaths: types.number,
  newDeaths: types.number,
  newCases: types.number,
  currentlyHospitalized: types.number,
  dataCurrency: types.Date,
  casesPer1M: types.number,
  percentPositive: types.maybeNull(types.number),
})

export const Graphs = types
  .model('Graphs', {
    currentStateCode: types.enumeration<StateCode>(
      'StateCode',
      Object.values(StateCode)
    ),
    stateSummary: types.maybe(StateSummary),
    charts: types.array(GraphChart),
    isFetching: types.maybeNull(types.boolean),
    dateRange: DateRangeTreeType,
  })
  .actions((self) => {
    function updateTestChart(data: TestingData) {
      const newTestsChart = self.charts[2]
      newTestsChart.startDate = self.dateRange.start
      newTestsChart.endDate = self.dateRange.end

      const normalizedData = normalizeData(
        DateRange.fromDates(self.dateRange.start, self.dateRange.end),
        data.map((x) => ({ dataDate: x.dataDate, number: x.testCount }))
      )

      newTestsChart.data.replace(normalizedData.data)
      newTestsChart.sevenDayAverage.replace(normalizedData.sevenDayAverage)
      newTestsChart.isFetching = false
    }

    const fetchData = flow(function* () {
      // update charts
      self.charts.forEach((x) => {
        x.errored = false
        x.isFetching = true
      })

      const dateRangePlusExtra = DateRange.fromDates(
        self.dateRange.start,
        self.dateRange.end
      ).daysFromStart(-8)

      const dataPromise = fetchCDCData(
        self.currentStateCode,
        dateRangePlusExtra
      )

      const testDataPromise = fetchTestingData(
        self.currentStateCode,
        dateRangePlusExtra
      )

      const [data, fetchedTestData]: [
        DailyData,
        TestingData
      ] = yield Promise.all([dataPromise, testDataPromise])

      if (!data || !fetchedTestData) {
        return
      }

      self.charts.slice(0, 2).forEach((x, index) => {
        x.startDate = self.dateRange.start
        x.endDate = self.dateRange.end

        const mappedValues = data.map((x) => ({
          number: supportedCharts.charts[index].selectValue(x),
          dataDate: x.dataDate,
        }))
        const resultingData = normalizeData(
          DateRange.fromDates(self.dateRange.start, self.dateRange.end),
          mappedValues
        )

        x.data.clear()
        x.data.replace(resultingData.data)
        x.sevenDayAverage.clear()
        x.sevenDayAverage.replace(resultingData.sevenDayAverage)
        x.isFetching = false
      })

      updateTestChart(fetchedTestData)

      // TODO: improve this
      const cases7dayAverages = self.charts[0].sevenDayAverage
      const mostRecent7DayAverageIndex = findLastIndex(
        cases7dayAverages,
        (x) => !!x
      )
      const sevenDayAverageValue =
        mostRecent7DayAverageIndex !== -1
          ? cases7dayAverages[mostRecent7DayAverageIndex]
          : 0

      const tests7DayAverages = self.charts[2].sevenDayAverage
      const testsMostRecent7DayAverageIndex = findLastIndex(
        tests7DayAverages,
        (x) => !!x
      )
      const tests7DayAveragesValue =
        testsMostRecent7DayAverageIndex !== -1
          ? tests7DayAverages[testsMostRecent7DayAverageIndex]
          : 0

      // TODO: update this, it's gross - can this be done separate of the actual
      // date fetching etc?
      self.stateSummary = StateSummary.create({
        currentlyHospitalized: 0,
        dataCurrency: getDateFromNumber(data[0].date),
        newCases: data[0].positiveIncrease,
        newDeaths: data[0].deathIncrease,
        totalDeaths: data[0].death,
        casesPer1M: Math.floor(
          (sevenDayAverageValue ?? 0) /
            (stateMap[self.currentStateCode].population / 1000000)
        ),
        percentPositive: !tests7DayAveragesValue
          ? null
          : (sevenDayAverageValue ?? 0) / tests7DayAveragesValue,
      })

      self.isFetching = false
    })

    const setState = flow(function* (stateCode: StateCode) {
      if (stateCode === self.currentStateCode) {
        return
      }
      self.currentStateCode = stateCode
      self.isFetching = true
      yield fetchData()
    })

    const setDateRange = flow(function* (dateRange: DateRange) {
      self.dateRange = DateRangeTreeType.create({
        numberOfDays: dateRange.numberOfDays,
        start: dateRange.start,
        end: dateRange.end,
      })
      self.isFetching = true
      yield fetchData()
    })

    const afterCreate = flow(function* () {
      self.isFetching = true
      // fetch state
      supportedCharts.charts.forEach((x) =>
        self.charts.push(
          GraphChart.create({
            errored: false,
            isFetching: true,
            name: x.name,
            startDate: new Date(),
            endDate: new Date(),
            format: x.valueFormat,
          })
        )
      )

      self.stateSummary = undefined
      yield fetchData()
    })

    return {
      afterCreate,
      setState,
      setDateRange,
    }
  })
  .views((self) => ({
    get isFetchingData() {
      return self.isFetching ?? false
    },
  }))
