import { DateRange } from '../util/DateRange'
import { sevenDayAverageInPlace } from './transforms'

type DataIn = Array<{ dataDate: Date; number: number | null }>
type DataOut = Array<{ dataDate: Date; number: number | null }>

export function extendData(dateRange: DateRange, data: DataIn): DataOut {
  let result: Array<{ dataDate: Date; number: number | null }> = data.slice()

  let firstDate = result.length ? result[0].dataDate : dateRange.end
  while (firstDate > dateRange.start) {
    const newDate = new Date(firstDate)
    newDate.setDate(newDate.getDate() - 1)
    result.unshift({ dataDate: newDate, number: null })
    firstDate = result[0].dataDate
  }

  let lastDate = result.length
    ? result[result.length - 1].dataDate
    : dateRange.start

  while (lastDate < dateRange.end) {
    const newDate = new Date(lastDate)
    newDate.setDate(newDate.getDate() + 1)
    result.push({ dataDate: newDate, number: null })
    lastDate = result[result.length - 1].dataDate
  }

  return result
}

type NormalizedData = {
  data: Array<number | null>
  sevenDayAverage: Array<number | null>
}

// TODO:
// map data for respective charts
// sort the data to what's needed
// extend the data
// take/apply 7 day average
// chop/filter data
export function normalizeData(
  dateRange: DateRange,
  data: DataIn
): NormalizedData {
  data.sort((a, b) => {
    if (a.dataDate < b.dataDate) return -1
    if (a.dataDate > b.dataDate) return 1
    return 0
  })

  const extendedData: Array<{
    dataDate: Date
    number: number | null
    sevenDayAverage?: number | null
  }> = extendData(dateRange, data)

  sevenDayAverageInPlace(extendedData)

  const filtered = extendedData.filter(
    (x) => x.dataDate >= dateRange.start && x.dataDate <= dateRange.end
  )

  return {
    data: filtered.map((x) => x.number),
    sevenDayAverage: filtered.map((x) => x.sevenDayAverage ?? null),
  }
}
