import { Rect, Point } from './types'
import { Touch } from 'react'

function containerOrigin(container: SVGSVGElement): Point {
  const clientRect = container.getBoundingClientRect()
  return { x: clientRect.x + window.scrollX, y: clientRect.y + window.scrollY }
}

export function withVerticalMouseStep(
  container: SVGSVGElement,
  bounds: Rect,
  step: number,
  listener: (
    currentStep: number,
    xLocation: number,
    mouseLocation: { x: number; y: number }
  ) => void
) {
  let currentStep = -1
  // convert container coordinates and bounding rect to page coordinates.
  let pageOrigin: Point | null = null
  let boundsOrigin: Point | null = null

  function ensureOrigins() {
    if (pageOrigin) {
      return
    }

    pageOrigin = containerOrigin(container)
    boundsOrigin = {
      x: pageOrigin.x + bounds.x,
      y: pageOrigin.y + bounds.y,
    }
  }

  function pointerHandler(event: MouseEvent | Touch) {
    ensureOrigins()

    if (!pageOrigin || !boundsOrigin) {
      return
    }

    const mousePageX = event.pageX,
      mousePageY = event.pageY

    const containerX = mousePageX - pageOrigin.x,
      containerY = mousePageY - pageOrigin.y

    let mouseStep = -1

    if (
      mousePageX > boundsOrigin.x &&
      mousePageX < boundsOrigin.x + bounds.width &&
      mousePageY > boundsOrigin.y &&
      mousePageY < boundsOrigin.y + bounds.height
    ) {
      mouseStep = Math.floor((mousePageX - boundsOrigin.x) / step)
    }

    if (mouseStep !== currentStep) {
      currentStep = mouseStep
      listener(currentStep, currentStep * step + step / 2, {
        x: containerX,
        y: containerY,
      })
    }
  }

  function mouseHandler(evt: MouseEvent) {
    evt.preventDefault()
    pointerHandler(evt)
  }

  container.addEventListener('mousemove', mouseHandler)
  container.addEventListener('mouseout', mouseHandler)

  function handleTouch(evt: TouchEvent) {
    if (!evt.changedTouches.length) {
      return
    }

    const te = evt.changedTouches[0]

    pointerHandler(te)
  }

  function touchStart(evt: TouchEvent) {
    console.log('touch start')
    handleTouch(evt)
  }

  function touchMove(evt: TouchEvent) {
    //evt.preventDefault()
    handleTouch(evt)
  }

  function touchEnd(evt: TouchEvent) {
    evt.preventDefault()
    handleTouch(evt)
  }

  container.addEventListener('touchstart', touchStart, false)
  container.addEventListener('touchmove', touchMove, false)
  container.addEventListener('touchend', touchEnd, false)

  return {
    cleanUp: () => {
      container.removeEventListener('mousemove', mouseHandler)
      container.removeEventListener('mouseout', mouseHandler)

      container.removeEventListener('touchstart', touchStart)
      container.removeEventListener('touchmove', touchMove)
      container.removeEventListener('touchend', touchEnd)
    },
  }
}
