export function findLastIndex<N>(arr: Array<N>, predicate: (n: N) => boolean) {
  let length = arr.length

  while (length--) {
    if (predicate(arr[length])) {
      return length
    }
  }

  return -1
}
