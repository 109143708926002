export type DayData = {
  death: number
  dateModified?: string
  date: number
  dataDate: Date
  dataQualityGrade?: string
  positiveIncrease: number
  totalTestResultsIncrease?: number
  deathIncrease: number
  inIcuCurrently?: number
  hospitalizedCurrently?: number
}

export type DailyData = Array<DayData>

export type TestingData = Array<{
  date: string
  dataDate: Date
  testCount: number
}>

export type CaseData = Array<{
  date: string
  dataDate: Date
  caseIncrease: number
}>

export type DeceasedData = Array<{
  date: string
  dataDate: Date
  deceasedIncrease: number
  totalDeceased: number
}>

export enum ValueFormat {
  normal = 'normal',
  percent = 'percent',
}

export enum ChartType {
  bar = 'bar',
  line = 'line',
}

export type DateRange = {
  start: Date
  end: Date
}
