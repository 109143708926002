import { useViewportSize } from './useViewportSize'

type LayoutSize = {
  readonly isSmallest: boolean
  readonly isMediumOrLarger: boolean
  readonly isLargest: boolean
}

export function useLayoutSize(): LayoutSize {
  const { width } = useViewportSize()

  return {
    isSmallest: width < 600,
    isMediumOrLarger: width >= 600,
    isLargest: width >= 1080,
  }
}
