import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { StateCode, stateMap, states } from './config'
import { ChevronDown, ChevronUp } from './icons'
import { StateButton } from './stateOutline/StateButton'

function StatePickerContainer({ children }: { children: React.ReactNode }) {
  return ReactDOM.createPortal(children, document.body)
}

export const StatePickerV2 = React.memo(function StatePickerV2({
  selectedState,
  statePicked,
}: {
  selectedState: StateCode
  statePicked: (stateCode: StateCode) => void
}) {
  const [isOpen, setIsOpen] = useState(false)

  const pickerClass = `picker ${isOpen ? 'showing' : 'notshowing'}`
  const pickerOverlayClass = `picker-overlay ${
    isOpen ? 'showing' : 'notshowing'
  }`

  function toggle() {
    const newState = !isOpen

    if (newState) {
      freeze()
    } else {
      unfreeze()
    }

    setIsOpen(newState)
  }

  useEffect(() => {
    setIsOpen(false)
    unfreeze()
  }, [selectedState])

  function stateSelected(stateCode: StateCode) {
    statePicked(stateCode)
  }

  return (
    <>
      <div className={'picker-button-container'}>
        <div className={'chevron-container'}>
          <ChevronUp />
          <ChevronDown />
        </div>
        <StateButton
          name={stateMap[selectedState].name}
          code={selectedState}
          isSelected={true}
          onSelected={toggle}
        />
      </div>
      <StatePickerContainer>
        <div className={pickerOverlayClass} onClick={toggle}></div>
        <div className={pickerClass}>
          <div className={'picker-container'}>
            {states.map((s) => (
              <StateButton
                key={s.code}
                name={s.name}
                showLabel={true}
                code={s.code}
                isSelected={selectedState === s.code}
                onSelected={stateSelected}
              />
            ))}
          </div>
        </div>
      </StatePickerContainer>
    </>
  )
})

// Potentially a way to freeze scrolling when showing picker??
function freeze() {
  var top = window.scrollY

  document.body.style.overflow = 'hidden'

  window.onscroll = function () {
    window.scroll(0, top)
  }
}

function unfreeze() {
  document.body.style.overflow = ''
  window.onscroll = null
}
