export class DateRange {
  private _numberOfDays: number = 0

  public get numberOfDays() {
    return this._numberOfDays
  }

  public start: Date
  public end: Date

  daysFromStart = (days: number): DateRange => {
    const newStart = new Date(this.start)
    newStart.setDate(newStart.getDate() + days)

    if (this.end < newStart) {
      throw new Error('End date is before start date')
    }

    const n = new DateRange()
    n.start = newStart
    n.end = this.end
    n._numberOfDays = this.numberOfDays - days

    return n
  }

  static fromDates(start: Date, end: Date): DateRange {
    if (end < start) {
      throw new Error('End date is before start date')
    }

    const n = new DateRange()
    n.start = new Date(start)
    n.end = new Date(end)
    n._numberOfDays = Math.floor(
      (end.getTime() - (start.getTime() - 100)) / 1000 / 60 / 60 / 24
    )
    return n
  }

  static fromDays(numberOfDays: number): DateRange {
    const start = new Date()
    start.setDate(start.getDate() - numberOfDays)

    const n = new DateRange()
    n.start = new Date(start)
    n.end = new Date()
    n._numberOfDays = numberOfDays
    return n
  }
}
