type StateConfig = {
  code: StateCode
  name: string
  population: number
}

export enum StateCode {
  us = 'us',
  al = 'al',
  ak = 'ak',
  az = 'az',
  ar = 'ar',
  ca = 'ca',
  co = 'co',
  ct = 'ct',
  de = 'de',
  dc = 'dc',
  fl = 'fl',
  ga = 'ga',
  hi = 'hi',
  id = 'id',
  il = 'il',
  in = 'in',
  ia = 'ia',
  ks = 'ks',
  ky = 'ky',
  la = 'la',
  me = 'me',
  md = 'md',
  ma = 'ma',
  mi = 'mi',
  mn = 'mn',
  ms = 'ms',
  mo = 'mo',
  mt = 'mt',
  ne = 'ne',
  nv = 'nv',
  nh = 'nh',
  nj = 'nj',
  nm = 'nm',
  ny = 'ny',
  nc = 'nc',
  nd = 'nd',
  oh = 'oh',
  ok = 'ok',
  or = 'or',
  pa = 'pa',
  ri = 'ri',
  sc = 'sc',
  sd = 'sd',
  tn = 'tn',
  tx = 'tx',
  ut = 'ut',
  vt = 'vt',
  va = 'va',
  wa = 'wa',
  wv = 'wv',
  wi = 'wi',
  wy = 'wy',
}

function sortStateConfig(a: StateConfig, b: StateConfig) {
  if (a.code === 'us') {
    return -1
  } else if (b.code === 'us') {
    return 1
  }

  if (a.name > b.name) {
    return 1
  } else if (a.name < b.name) {
    return -1
  }
  return 0
}

export const states: Array<StateConfig> = [
  { code: StateCode.us, name: 'United States', population: 331449281 },
  { code: StateCode.al, name: 'Alabama', population: 5024279 },
  { code: StateCode.ak, name: 'Alaska', population: 733391 },
  { code: StateCode.az, name: 'Arizona', population: 7151502 },
  { code: StateCode.ar, name: 'Arkansas', population: 3011524 },
  { code: StateCode.ca, name: 'California', population: 39538223 },
  { code: StateCode.co, name: 'Colorado', population: 5773714 },
  { code: StateCode.ct, name: 'Connecticut', population: 3605944 },
  { code: StateCode.de, name: 'Delaware', population: 989948 },
  { code: StateCode.dc, name: 'District of Columbia', population: 689545 },
  { code: StateCode.fl, name: 'Florida', population: 21538187 },
  { code: StateCode.ga, name: 'Georgia', population: 10711908 },
  { code: StateCode.hi, name: 'Hawaii', population: 1455271 },
  { code: StateCode.id, name: 'Idaho', population: 1839106 },
  { code: StateCode.il, name: 'Illinois', population: 12812508 },
  { code: StateCode.in, name: 'Indiana', population: 6785528 },
  { code: StateCode.ia, name: 'Iowa', population: 3190369 },
  { code: StateCode.ks, name: 'Kansas', population: 2937880 },
  { code: StateCode.ky, name: 'Kentucky', population: 4505836 },
  { code: StateCode.la, name: 'Louisiana', population: 4657757 },
  { code: StateCode.me, name: 'Maine', population: 1362359 },
  { code: StateCode.md, name: 'Maryland', population: 6177224 },
  { code: StateCode.ma, name: 'Massachusetts', population: 7029917 },
  { code: StateCode.mi, name: 'Michigan', population: 10077331 },
  { code: StateCode.mn, name: 'Minnesota', population: 5706494 },
  { code: StateCode.ms, name: 'Mississippi', population: 2961279 },
  { code: StateCode.mo, name: 'Missouri', population: 6154913 },
  { code: StateCode.mt, name: 'Montana', population: 1084225 },
  { code: StateCode.ne, name: 'Nebraska', population: 1961504 },
  { code: StateCode.nv, name: 'Nevada', population: 3104614 },
  { code: StateCode.nh, name: 'New Hampshire', population: 1377529 },
  { code: StateCode.nj, name: 'New Jersey', population: 9288994 },
  { code: StateCode.nm, name: 'New Mexico', population: 2117522 },
  { code: StateCode.ny, name: 'New York', population: 20201249 },
  { code: StateCode.nc, name: 'North Carolina', population: 10439388 },
  { code: StateCode.nd, name: 'North Dakota', population: 779094 },
  { code: StateCode.oh, name: 'Ohio', population: 11799448 },
  { code: StateCode.ok, name: 'Oklahoma', population: 3959353 },
  { code: StateCode.or, name: 'Oregon', population: 4237256 },
  { code: StateCode.pa, name: 'Pennsylvania', population: 13011844 },
  { code: StateCode.ri, name: 'Rhode Island', population: 1097379 },
  { code: StateCode.sc, name: 'South Carolina', population: 5118425 },
  { code: StateCode.sd, name: 'South Dakota', population: 886667 },
  { code: StateCode.tn, name: 'Tennessee', population: 6910840 },
  { code: StateCode.tx, name: 'Texas', population: 29145505 },
  { code: StateCode.ut, name: 'Utah', population: 3271616 },
  { code: StateCode.vt, name: 'Vermont', population: 643077 },
  { code: StateCode.va, name: 'Virginia', population: 8631393 },
  { code: StateCode.wa, name: 'Washington', population: 7705281 },
  { code: StateCode.wv, name: 'West Virginia', population: 1793716 },
  { code: StateCode.wi, name: 'Wisconsin', population: 5893718 },
  { code: StateCode.wy, name: 'Wyoming', population: 576851 },
].sort(sortStateConfig)

// @ts-ignore
export const stateMap: { [key in StateCode]: StateConfig } = Object.fromEntries(
  states.map((s) => [s.code, s])
)
